import React, {useEffect, useRef, useState} from "react";
import {FloatingLabel} from "flowbite-react";
import {useStore} from "../../../../data/state/store";
import PoIModel from "../../../../data/state/models/PoIModel";
import { Instance} from "mobx-state-tree";
import useLogger, {LogLevel} from "../../../hooks/Logger";
import {getPoIAscendants} from "../../../../utils/poiUtils";
import {useIntl} from "react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretRight, faCompass, faDiamond, faServer} from "@fortawesome/free-solid-svg-icons";
import {AUTOCOMPLETE_DELAY} from "../../../../config/global";
import usePoI from "../../../hooks/PoI";

interface PoIAutocompleteProps {
    unityMessenger: (gameObjectName: string, methodName: string, parameter?: any) => void,
    maxResults?: number
}

export const PoIAutocomplete = ( {unityMessenger, maxResults = 3}: PoIAutocompleteProps ) => {

    const {poiStore, viewStore} = useStore()
    const [searchTerm, setSearchTerm] = useState("")
    const [results, setResults] = useState<Instance<typeof PoIModel>[]>([])
    const [showResults, setShowResults] = useState(false)
    const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(null)
    const intl = useIntl()
    const {selectPoI, loadPoIModel, goToPoIPosition, sendPoIList, removeMarkers} = usePoI()
    const {logger} = useLogger()

    const unityMessengerRef = useRef(unityMessenger)
    unityMessengerRef.current = unityMessenger

    const handleInputChange = (event: any) => {

        // Si ya hay un temporizador, lo cancelamos
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        setSearchTerm(event.target.value);

        if (searchTerm.length >= 3) {

            setSearchTimeout(setTimeout(() => {

                setShowResults(true);
                let resultsFound = searchTree(poiStore.tree!, searchTerm);
                setResults(resultsFound);

            }, AUTOCOMPLETE_DELAY));

        } else setResults([]);

    }

    useEffect(() => {

    }, [searchTerm]);

    const searchTree = (node: Instance<typeof PoIModel>, term: string): Instance<typeof PoIModel>[] =>  {

        let res: Instance<typeof PoIModel>[] = [];

        const nodeName = node.name?.toLowerCase()
        term = term.toLowerCase()

        if (nodeName?.includes(term)) {
            res.push(node);
        }
        if (node.children && res.length < maxResults) {
            for (let child of node.children) {
                res = res.concat(searchTree(child, term));
                if (res.length >= maxResults) break;
            }
        }
        return res;

    }

    const handleClickOnResult = (event: any, poi: Instance<typeof PoIModel>) => {
        selectPoI(poi)
        setShowResults(false)


        if ( viewStore.view.currentScene !== viewStore.view.sceneURI ) {

            logger(`Loading new scene for PoI with id: ${poi.id}`, LogLevel.INFO)

            loadPoIModel(unityMessengerRef.current, poiStore.scenePoI!).then(r => {
            })
        } else {

            logger(`Going to PoI position for PoI with id: ${poi.id}`, LogLevel.INFO)

            goToPoIPosition(unityMessengerRef.current, poi.cameraPosition, poi.cameraTargetPosition).then(r => {
            })

            // Si el PoI no carga su propia escena, enviamos los marcadores que se representan en la escena actual
            if (!poi.loadsNewScene) {
                sendPoIList(unityMessengerRef.current, poi).then(r => {
                    logger(`PoI list sent to Unity for PoI with id: ${poi.id}`, LogLevel.INFO)
                })
            } else {
                removeMarkers(unityMessengerRef.current).then(r => {
                    logger(`Markers removed from scene`, LogLevel.INFO)
                })
            }
        }

    }

    return (
        <>
            <div>

                <FloatingLabel
                    onFocus={() => setShowResults(true)}
                    onChange={(event) => handleInputChange(event)}
                    value={searchTerm}
                    variant="filled" label={intl.formatMessage({id: "poi.autocomplete.search"})} />
                {showResults && (

                    <ul className="absolute left-0 mt-2 w-full bg-white shadow-lg overflow-hidden z-30">
                        {results.map((poi, index) => (
                            <li key={index}
                                className="text-gray-900 cursor-pointer hover:bg-gray-200 p-2">
                                <label className={"block"}
                                       onClick={ (event) => handleClickOnResult(event, poi) }>
                                    {poi.type === "ASSET" && <FontAwesomeIcon icon={faDiamond} className={"px-1"} color={"#4B5563"}/>}
                                    {poi.type === "SENSOR" && <FontAwesomeIcon icon={faServer} className={"px-1"} color={"#4B5563"}/>}
                                    {poi.type === "MARKER" && <FontAwesomeIcon icon={faCompass} className={"px-1"} color={"#4B5563"}/>}
                                    &nbsp;
                                    {poi.name}
                                </label>

                                {poiStore.tree && (
                                    <>
                                        {getPoIAscendants(poiStore.tree, poi, false).map((ascendant, index) => (
                                            <>
                                                <small key={index} className={""}
                                                       onClick={ (event) => handleClickOnResult(event, ascendant) }>
                                                    <FontAwesomeIcon icon={faCaretRight} className={"px-1"} color={"#4B5563"}/>
                                                    &nbsp;
                                                    {ascendant.name}
                                                    &nbsp;
                                                </small>
                                            </>
                                        ))}
                                    </>

                                )}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </>
    );
};
