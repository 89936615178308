export const UNITY_BUILD_PATH = process.env.PUBLIC_URL + "/unity/Build/"
export const UNITY_BUILD_NAME = "Victor"
export const UNITY_VIEWER_POI_DEPTH = 1

export const UNITY_BUILD_SUFFIXES = {

    LOADER_SUFFIX: ".loader.js",
    DATA_SUFFIX: ".data",
    FRAMEWORK_SUFFIX: ".framework.js",
    CODE_SUFFIX: ".wasm",

}

export const UNITY_CONFIG = {
    loaderUrl: UNITY_BUILD_PATH + UNITY_BUILD_NAME + UNITY_BUILD_SUFFIXES.LOADER_SUFFIX,
    dataUrl: UNITY_BUILD_PATH + UNITY_BUILD_NAME + UNITY_BUILD_SUFFIXES.DATA_SUFFIX,
    frameworkUrl: UNITY_BUILD_PATH + UNITY_BUILD_NAME + UNITY_BUILD_SUFFIXES.FRAMEWORK_SUFFIX,
    codeUrl: UNITY_BUILD_PATH + UNITY_BUILD_NAME + UNITY_BUILD_SUFFIXES.CODE_SUFFIX,
}

export const UNITY_CANVAS_ID = "gedig-3d-viewer"
export const UNITY_COMM_OBJECT = "JSCommandsUnity"
export const WIDGETS_GRID_ID = "widgets-grid"

export const UNITY_FUNCTION_NAMES = {

    ENTER_POI: "EnterPOI",
    LOAD_MODEL: "LoadModel",
    SET_LANGUAGE: "SetLanguage",
    SEND_POI_LIST: "LoadPOIsFromString",
    GO_TO_POI: "SetCameraPositionAndTarget",
    ON_MOUSE_ENTER_POI: "OnMouseEnterPoI",
    ON_MOUSE_LEAVE_POI: "OnMouseLeavePoI",
    TOGGLE_OBJECT: "ToggleObjectRenderer",
    TOGGLE_POI_EDITOR: "TogglePOIEditor",
    UPDATE_POI_MARKER_POSITION: "UpdatePOIPosition",

    // CAMERA COMMANDS
    RESET_CAMERA: "ResetCamera",
    SET_CAMERA_HANDLE_TYPE: "SetCameraHandleType",
    GET_CAMERA_POSITIONS: "GetCameraAndTarget"
}

export const UNITY_FUNCTION_LISTENERS = {

    ON_CAMERA_UPDATE: "OnCameraUpdate",
    ON_VIEWER_LOADED: "PlayerReady",
    ON_SCENE_LOADED: "SceneLoaded",
    ON_CAMERA_POSITION_REQUEST: "OnGetCameraAndTarget",
    ON_CLICK_ON_POI: "ClickOnPOI"

}
