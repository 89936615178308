// @ts-ignore
import splashLogoDark from "../assets/multimedia/imgs/logos/principal_negativo.svg";
// @ts-ignore
import splashLogo from "../assets/multimedia/imgs/logos/principal_positivo.svg";
import {TEMP_UNITS} from "../data/types/misc";


export const ROOT_POI_ID: number = 5838;
export const CAROUSEL_SLIDE_DURATION: number = 5000;
export const MIN_DEPTH_EXPAND_POI: number = 3;

export const SPLASH_LOGO = splashLogo;
export const SPLASH_LOGO_DARK = splashLogoDark;


// MAYOR MODE CONFIG
export const MAYOR_MODE: boolean = true

// AUTOCOMPLETE CONFIG
export const AUTOCOMPLETE_DELAY: number = 500;

// MAP
export const SHOW_LEAF_POI_MARKER = true;
export const INITIAL_MAP_ZOOM: number = 15;

// MISC

export const TEMPERATURE_UNIT: TEMP_UNITS = TEMP_UNITS.C;

// 3D VIEWER
export const DEFAULT_CAMERA_TRAVEL_SPEED: number = 1.0; // VALUE IN SECONDS

// GENERAL
export const DEV_MODE: boolean = true;
export const ENTER_ON_SELECTED_POI = false // Determines if you will change the scene clicking again on the selected PoI in the PoI menu
