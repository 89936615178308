import {types} from "mobx-state-tree";
import {ModelConstants} from "../models/ModelConstants";
import LanguageModel from "../models/LanguageModel";
import {Language} from "../../types/language";

export const LanguageStore = types.model(ModelConstants.LanguageModel, {

    language: LanguageModel

})

.actions(self => ({

   setLanguage(language: Language   ) {

         self.language.name = language.name;
         self.language.isoCode = language.isoCode;
         self.language.default = language.default;

   }

}));
