import "./styles.css"
import {useStore} from "../../../data/state/store";
import PoINode from "./PoINode/PoINode";
import {observer} from "mobx-react";
import {ReactUnityEventParameter} from "react-unity-webgl/distribution/types/react-unity-event-parameters";
import PoIInfo from "./PoINode/PoIInfo/PoIInfo";
import {PoIAutocomplete} from "./Autocomplete/PoIAutocomplete";
import {PoIMenuLegend} from "./PoIMenuLegend/PoIMenuLegend";
import {useEffect, useRef} from "react";

interface PoIMenuProps {
    unityMessenger: (gameObjectName: string, methodName: string, parameter?: ReactUnityEventParameter) => void
}

const PoIMenu = observer(({unityMessenger}: PoIMenuProps) => {
    const {poiStore, viewStore} = useStore()
    const selectedNodeRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        // Cuando cambia el nodo seleccionado, desplazarse hasta él
        if (selectedNodeRef.current) {

            selectedNodeRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [poiStore.selectedPoI]);

    const renderTree = (node: any) => (
        <>
            <div ref={node.id === poiStore.selectedPoI?.id ? selectedNodeRef : null}>
                <PoINode unityMessenger={unityMessenger} poi={node}/>
            </div>
        </>
    );

    return (
        <>
            <div className={`${!viewStore.view.sceneLoaded ? 
                "bg-opacity-80 z-40 bg-gray-800 absolute left-0 top-0 w-full h-full border-0 border-amber-300" 
                : "hidden"}`}>

            </div>
            <div className="h-16" id={"poi-filters"}>
                <PoIAutocomplete unityMessenger={unityMessenger} maxResults={3}/>
            </div>

            <div className={"w-full"}>
                <PoIMenuLegend/>
            </div>

            {!poiStore.tree && (

                <div role="status" className="max-w-md p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow
                    animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700">
                    <div className="flex items-center justify-between">
                        <div>
                            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                        </div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                    </div>
                    <div className="flex items-center justify-between pt-4">
                        <div>
                            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                        </div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                    </div>
                    <div className="flex items-center justify-between pt-4">
                        <div>
                            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                        </div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                    </div>
                    <div className="flex items-center justify-between pt-4">
                        <div>
                            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                        </div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                    </div>
                    <div className="flex items-center justify-between pt-4">
                        <div>
                            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                        </div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                    </div>
                    <span className="sr-only">Loading...</span>
                </div>

            )}

            <ul>
                {viewStore.view.navigationPanelTabs.map(tab => (
                    <li>
                        <button
                            className={`w-full text-left p-4 ${tab.active ? "bg-gray-800 text-white" : "bg-gray-900 text-gray-200"} hover:bg-gray-700`}
                            onClick={() => {
                                viewStore.setActiveNavigationPanelTab(tab.id)
                            }}
                        >
                            {tab.name}
                        </button>
                    </li>
                ))}
            </ul>

            <div className={"poi-menu overflow-y-auto max-h-full pb-12 scroll-pb-12"}>
                <ul>

                    {poiStore.tree && renderTree(poiStore.tree)}
                </ul>
            </div>

            <PoIInfo/>

        </>
    );
});

export default PoIMenu;
