import {ModelConstants} from "./ModelConstants";
import {types} from "mobx-state-tree";


const InfoWeatherModel = types.model(ModelConstants.InfoWeatherModel, {

    temp: types.maybe(types.number),
    humidity: types.maybe(types.number),
    pressure: types.maybe(types.number),
    windSpeed: types.maybe(types.number),
    clouds: types.maybe(types.number)

});


export default InfoWeatherModel;

