import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCompass} from "@fortawesome/free-solid-svg-icons";
import {useStore} from "../../../data/state/store";

export const BottomNavigationMenu = () => {

    const {viewStore} = useStore();

    const togglePoINavigationMenu = () => {

        viewStore.togglePoINavigationPanel()

    }


    return (

        <>

            <div
                className="fixed bottom-0 left-0 z-50 w-full h-16 bg-gray-700 border-t border-gray-600 dark:bg-gray-700 dark:border-gray-600">
                <div className="grid h-full max-w-lg grid-cols-1 mx-auto font-medium">
                    <button type="button"
                            onClick={togglePoINavigationMenu}
                            className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">

                        <FontAwesomeIcon icon={faCompass} size={"2x"} className="text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500"/>

                    </button>

                </div>
            </div>
        </>
    );
};
