import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {
    faCloud, faWind, faWater, faThermometer2, faDroplet
} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {useStore} from "../../../data/state/store";
import {getWeatherData} from "../../../data/services/weatherService";
import {Badge} from "flowbite-react";
import {TEMP_UNITS} from "../../../data/types/misc";
import {TEMPERATURE_UNIT} from "../../../config/global";

interface PoIWeatherWidgetProps {

    colSpan?: number,
    rowSpan?: number,
    colStart?: number,
    rowStart?: number,
}

const PoIWeatherWidget = observer(({colSpan, rowSpan, colStart, rowStart}: PoIWeatherWidgetProps) => {

    const [isLoaded, setIsLoaded] = useState(false)
    const [isReloading, setIsReloading] = useState(false);
    const {poiStore, globalStore, viewStore} = useStore()

    const reloadWeatherData = () => {

        setIsReloading(true)

        if (poiStore.scenePoI && (poiStore.scenePoI.lat && poiStore.scenePoI.lon))
            getWeatherData(poiStore.scenePoI!.lat, poiStore.scenePoI!.lon)
                .then(response => {
                    poiStore.setPoIInfoWeather(poiStore.scenePoI!, response)
                    setTimeout(() => {
                        setIsReloading(false)
                    }, 2000)
                })

    }

    // Convert the temperature from a given unit to another unit. Float is 2 decimal places
    const convertTemperature = (from: TEMP_UNITS, value: number | string, target: TEMP_UNITS) => {

        let temp = parseFloat(value.toString())

        if (from === TEMP_UNITS.C && target === TEMP_UNITS.F) {
            temp = ((temp * 9 / 5) + 32)
        }

        if (from === TEMP_UNITS.C && target === TEMP_UNITS.K) {
            temp = (temp + 273.15)
        }

        if (from === TEMP_UNITS.F && target === TEMP_UNITS.C) {
            temp = ((temp - 32) * 5 / 9)
        }

        if (from === TEMP_UNITS.F && target === TEMP_UNITS.K) {
            temp = ((temp - 32) * 5 / 9 + 273.15)
        }

        if (from === TEMP_UNITS.K && target === TEMP_UNITS.C) {
            temp = (temp - 273.15)
        }

        if (from === TEMP_UNITS.K && target === TEMP_UNITS.F) {
            temp = ((temp - 273.15) * 9 / 5 + 32)
        }

        return temp.toFixed(1).replace(".", ",")

    }

    const getCloudsValue = (clouds: number | undefined) => {

        if (clouds === undefined) return;
        return 100 - clouds;

    }


    useEffect(() => {

        setIsLoaded(false)

        if (poiStore.scenePoI && (poiStore.scenePoI.lat && poiStore.scenePoI.lon)) {

            getWeatherData(poiStore.scenePoI.lat, poiStore.scenePoI.lon)
                .then(response => {

                    poiStore.setPoIInfoWeather(poiStore.scenePoI!, response)

                    // Un pequeño timeout antes de establecer la carga final
                    setTimeout(() => {
                        setIsLoaded(true)

                        setInterval(() => {
                            reloadWeatherData()
                        }, 60000)

                    }, 2000)
                })

        } else {
            setIsLoaded(true)
        }


    }, [poiStore, poiStore.scenePoI]);

    return (
        <>
            {viewStore.view.showWeatherWidget && (

                <div className={""}

                     style={{
                         gridColumnStart: colStart,
                         gridColumnEnd: `span ${colSpan}`,
                         gridRowStart: rowStart,
                         gridRowEnd: `span ${rowSpan}`,
                     }}
                >
                    {!isLoaded && (
                        <>
                            <div className={"justify-items-end items-center select-none px-1.5"}>
                                <div role="status" className={"max-w-sm animate-pulse items-center"}>
                                    <div
                                        className={"h-2.5 bg-gray-700 rounded-full dark:bg-gray-700 w-full mr-1.5 mt-1.5"}></div>
                                    <div
                                        className={"h-2.5 bg-gray-700 rounded-full dark:bg-gray-700 w-full mr-1.5 mt-1.5"}></div>
                                    <div
                                        className={"h-2.5 bg-gray-700 rounded-full dark:bg-gray-700 w-full mr-1.5 mt-1.5"}></div>
                                    <div
                                        className={"h-2.5 bg-gray-700 rounded-full dark:bg-gray-700 w-full mr-1.5 mt-1.5"}></div>
                                </div>
                            </div>
                        </>
                    )}

                    {isLoaded && poiStore.scenePoI?.infoWeather && (
                        <>
                            <div
                                className={`${globalStore.deviceScreenOrientation === "primary-portrait" ? 'flex-col' : 'flex-row'}
                                    mr-1.5 mt-1.5 select-none
                                    justify-items-end items-center`}>
                                {poiStore.scenePoI?.infoWeather?.temp && (
                                    <Badge size={"sm"} color={"gray"}
                                           className={`${globalStore.deviceScreenOrientation === "primary-portrait" ? 'mr-1.5' : 'mb-1'}`}>

                                        <div className={"grid-cols-6 grid w-100"}>
                                            <div className={"col-span-2 justify-items-center"}>
                                                <FontAwesomeIcon icon={faThermometer2}/>
                                            </div>
                                            <div className={"col-span-4"}>
                                                <span className="text-sm ml-1.5">
                                                    {convertTemperature(TEMP_UNITS.K, poiStore.scenePoI?.infoWeather?.temp, TEMPERATURE_UNIT)}&nbsp;
                                                    <small>°C</small>
                                                </span>
                                            </div>
                                        </div>
                                    </Badge>
                                )}

                                <Badge size={"sm"} color={"gray"}
                                       className={`${globalStore.deviceScreenOrientation === "primary-portrait" ? 'mr-1.5' : 'mb-1'}`}>

                                    <div className={"grid-cols-6 grid w-100"}>
                                        <div className={"col-span-2 justify-items-center"}>
                                            <FontAwesomeIcon icon={faCloud}/>
                                        </div>
                                        <div className={"col-span-4"}>
                                            <span className="text-sm ml-1.5">
                                                {getCloudsValue(poiStore.scenePoI?.infoWeather?.clouds)}<small>%</small>
                                            </span>
                                        </div>
                                        </div>
                                </Badge>

                                <Badge size={"sm"} color={"gray"}
                                       className={`${globalStore.deviceScreenOrientation === "primary-portrait" ? 'mr-1.5' : 'mb-1'}`}>

                                    <div className={"grid-cols-6 grid w-100"}>
                                        <div className={"col-span-2 justify-items-center"}>
                                            <FontAwesomeIcon icon={faWind}/>

                                        </div>
                                        <div className={"col-span-4"}>
                                            <span className="text-sm ml-1.5">
                                            {poiStore.scenePoI?.infoWeather?.windSpeed}&nbsp;<small>km/s</small>
                                            </span>
                                        </div>
                                    </div>
                                </Badge>


                                <Badge size={"sm"} color={"gray"}
                                       className={`${globalStore.deviceScreenOrientation === "primary-portrait" ? 'mr-1.5' : 'mb-1'}`}>

                                    <div className={"grid-cols-6 grid w-100"}>
                                        <div className={"col-span-2 justify-items-center"}>
                                            <FontAwesomeIcon icon={faDroplet}/>
                                        </div>
                                        <div className={"col-span-4"}>
                                            <span className="text-sm ml-1.5">
                                            {poiStore.scenePoI?.infoWeather?.humidity}<small>%</small>
                                        </span>
                                        </div>
                                    </div>
                                </Badge>

                            </div>
                        </>
                    )
                    }
                </div>

            )}

        </>
    )
        ;
});

export default PoIWeatherWidget;
