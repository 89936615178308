import {observer} from "mobx-react";
import {useStore} from "../../../data/state/store";
import SensorChart from "./SensorChart/SensorChart";
import {Fragment, useEffect, useState} from "react";
import {getSensorById} from "../../../data/services/sensorService";
import useLogger, {LogLevel} from "../../hooks/Logger";
import {Badge} from "flowbite-react";
import {getMeasurementsByCriteria} from "../../../data/services/measurementService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner, faTimes, faBell, faBellSlash} from "@fortawesome/free-solid-svg-icons";

interface PoISensorInfoWidgetProps {
    colSpan?: number;
    rowSpan?: number;
    colStart?: number;
    rowStart?: number;
    unityMessenger: (gameObjectName: string, methodName: string, parameter?: any) => void;
}

const PoISensorInfoWidget = observer(({
                                          colSpan,
                                          rowSpan,
                                          colStart,
                                          rowStart,
                                          unityMessenger
                                      }: PoISensorInfoWidgetProps) => {
    const {poiStore} = useStore();
    const [sensorData, setSensorData] = useState<any>(null);
    const [measurementsData, setMeasurementsData] = useState<any[]>([]);
    const [getLiveSensorData, setGetLiveSensorData] = useState<boolean>(true);
    const [updating, setUpdating] = useState<boolean>(true);
    const [hasAlert, setHasAlert] = useState<boolean>(false);


    const {logger} = useLogger();

    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        if (poiStore.selectedPoI?.type === "SENSOR" && poiStore.selectedPoI?.entityId) {
            logger(`Selected PoI: ${poiStore.selectedPoI?.id}. Related entity id: ${poiStore.selectedPoI?.entityId}`, LogLevel.INFO);

            getSensorById(poiStore.selectedPoI?.entityId!).then(sensor => {
                setSensorData(sensor);

                if (sensor.id && sensor.is_alive) {
                    getMeasurementDataStream(sensor.id);
                }
            });
        } else {
            setSensorData(null);
        }

        function getMeasurementDataStream(sensorId: number) {
            intervalId = setInterval(() => {

                setUpdating(true)

                if (sensorId && getLiveSensorData) {
                    logger(`Getting sensor ${sensorId} measurements...`, LogLevel.INFO);

                    const measurementParams = {
                        page_limit: 10,
                        page_number: 1,
                        sort_by: "measured_at",
                        sort_order: "desc",
                        entity_ids: null,
                        sensors_id: [sensorId],
                        raw: true,
                    };

                    getMeasurementsByCriteria(measurementParams).then(measurements => {
                        setMeasurementsData(measurements);
                    }).finally(() => setTimeout(() => setUpdating(false), 1000));
                }
            }, 15000);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
                logger("Interval cleared", LogLevel.INFO);
            }
        };
    }, [poiStore.selectedPoI]);

    return (
        <>
            {poiStore.selectedPoI && poiStore.selectedPoI.type === "SENSOR" && (
                <>
                    {!sensorData && <h1>Loading sensor data...</h1>}
                    {sensorData && (
                        <div
                            className="m-1.5 bg-gray-800 p-2"
                            style={{
                                pointerEvents: "auto",
                                gridColumnStart: colStart,
                                gridColumnEnd: `span ${colSpan}`,
                                gridRowStart: rowStart,
                                gridRowEnd: `span ${rowSpan}`,
                            }}
                        >
                            <div className="flex overflow-hidden">
                                <Badge
                                    className={"justify-center align-middle"}
                                    style={{minWidth: "35px"}}
                                    title={sensorData.is_alive ? "Currently receiving new data" : "Sensor is not alive"}
                                    color={sensorData.is_alive ? "green" : "gray"}
                                >
                                    {!updating && (
                                        <>{sensorData.is_alive ? "ON" : "OFF"}</>
                                    )}
                                    {updating && (
                                        <FontAwesomeIcon icon={sensorData.is_alive ? faSpinner : faTimes}
                                                         spin={sensorData.is_alive}/>
                                    )}

                                </Badge>
                                &nbsp;
                                <Badge
                                    className={"justify-center align-middle"}
                                    style={{minWidth: "35px", opacity: hasAlert ? 1 : 0.5}}
                                    title={sensorData.is_alive ? "Currently receiving new data" : "Sensor is not alive"}
                                    color={hasAlert ? "failure" : "gray"}
                                >

                                    <FontAwesomeIcon icon={hasAlert ? faBell : faBellSlash}/>


                                </Badge>


                                &nbsp;
                                <h1 title={sensorData.name} className="text-ellipsis text-nowrap text-white w-full">
                                    {sensorData.name}
                                </h1>
                            </div>
                            {measurementsData && measurementsData.length > 0 && (
                                <SensorChart measurements={measurementsData}/>
                            )}
                        </div>
                    )}
                </>
            )}
        </>
    );
});

export default PoISensorInfoWidget;
