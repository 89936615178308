import {Button, TextInput} from "flowbite-react";
import useLogger, {LogLevel} from "../../hooks/Logger";
import {PoIDataUpdate} from "../../../data/types/poi";
import {useEffect, useRef, useState} from "react";
import {useStore} from "../../../data/state/store";
import {savePoIData} from "../../../data/services/poiService";
import {observer} from "mobx-react";
import {getSnapshot} from "mobx-state-tree";
import {UnityService} from "../../../data/services/unityService";

interface PoIPositionEditorWidgetProps {

    colSpan?: number,
    rowSpan?: number,
    colStart?: number,
    rowStart?: number,
    unityMessenger: (gameObjectName: string, methodName: string, parameter?: any) => void
}

export const PoIPositionEditorWidget = observer((
    {colSpan, rowSpan, colStart, rowStart, unityMessenger}: PoIPositionEditorWidgetProps
) => {

    const {logger} = useLogger()
    const {poiStore, viewStore} = useStore()
    const intervalRef = useRef<NodeJS.Timeout | undefined>();


    const [x, setX] = useState<number>(poiStore.selectedPoI?.position.x | 0);
    const [y, setY] = useState<number>(poiStore.selectedPoI?.position.y | 0);
    const [z, setZ] = useState<number>(poiStore.selectedPoI?.position.z | 0);

    const update3DViewerPosition = () => {

        const position = {
            id: poiStore.selectedPoI?.id!,
            position: {
                x: x,
                y: y,
                z: z
            },
        }

        UnityService.updatePoIMarkerPosition(unityMessenger, JSON.stringify(position)).then(r =>
            logger("PoI position updated: " + r, LogLevel.INFO)
        )

    }

    const savePoIPosition = () => {

        logger("Saving camera and target position", LogLevel.INFO)
        const positions = {
            label: poiStore.selectedPoI?.name!,
            camera_position: {
                x: poiStore.selectedPoI?.cameraPosition.x!,
                y: poiStore.selectedPoI?.cameraPosition.y!,
                z: poiStore.selectedPoI?.cameraPosition.z!
            },
            camera_target: {
                x: poiStore.selectedPoI?.cameraTargetPosition.x!,
                y: poiStore.selectedPoI?.cameraTargetPosition.y!,
                z: poiStore.selectedPoI?.cameraTargetPosition.z!,
            },
            poi_position: {
                x: x,
                y: y,
                z: z
            },
            poi_assets: {}
        } as PoIDataUpdate

        savePoIData(poiStore.selectedPoI?.id!, positions).then(r => {
                logger("Camera positions saved: " + r, LogLevel.INFO)
                poiStore.setPoIPosition(poiStore.selectedPoI!, x, y, z)
            }
        )

    }

    const changeXPosition = (value: number) => {
        setX(value)
        update3DViewerPosition()
    }

    const changeYPosition = (value: number) => {
        setY(value)
        update3DViewerPosition()
    }

    const changeZPosition = (value: number) => {
        setZ(value)
        update3DViewerPosition()
    }

    useEffect(() => {

        if (poiStore.selectedPoI) {

            logger("Selected PoI: " + poiStore.selectedPoI?.id, LogLevel.INFO)
            console.log(getSnapshot(poiStore.selectedPoI))

            setX(poiStore.selectedPoI?.position.x | 0);
            setY(poiStore.selectedPoI?.position.y | 0);
            setZ(poiStore.selectedPoI?.position.z | 0);

        }

    }, [poiStore.selectedPoI]);


    const handleMouseDown = (axis: string, increment: number) => {

        logger("Mouse down on axis " + axis, LogLevel.INFO)

        intervalRef.current = setInterval(() => {

            switch (axis) {
                case "x":
                    setX((prevX) => prevX + increment);
                    break;
                case "y":
                    setY((prevY) => prevY + increment);
                    break;
                case "z":
                    setZ((prevZ) => prevZ + increment);
                    break;
            }

        }, 200);
    };

    const handleMouseUp = () => {
        clearInterval(intervalRef.current);
    };

    useEffect(() => {
        return () => {
            clearInterval(intervalRef.current);
        };
    }, []);

    return (
        <>
            {viewStore.view.editPoIPositionMode && (
                <div
                    className={"m-1.5 bg-gray-800 p-2 w-auto"}
                    style={{
                        pointerEvents: "auto",
                        gridColumnStart: colStart,
                        gridColumnEnd: `span ${colSpan}`,
                        gridRowStart: rowStart,
                        gridRowEnd: `span ${rowSpan}`,
                    }}>
                    <h1 className={"text-white mb-2"}>PoI marker position tool</h1>
                    <div className={"flex justify-items-center m-1.5 gap-1"}>
                        <Button
                            onMouseDown={() => handleMouseDown("x",-1)} onMouseUp={handleMouseUp}
                            onClick={() => changeXPosition(x - 1)}>-</Button>
                        <TextInput type="text" value={x} readOnly={false}/>
                        <Button
                            onMouseDown={() => handleMouseDown("x", 1)} onMouseUp={handleMouseUp}
                            onClick={() => changeXPosition(x + 1)}>+</Button>
                    </div>
                    <div className={"flex justify-items-center m-1.5 gap-1"}>
                        <Button
                            onMouseDown={() => handleMouseDown("y", -1)} onMouseUp={handleMouseUp}
                            onClick={() => changeYPosition(y - 1)}>-</Button>
                        <TextInput type="text" value={y}/>
                        <Button
                            onMouseDown={() => handleMouseDown("y", 1)} onMouseUp={handleMouseUp}
                            onClick={() => changeYPosition(y + 1)}>+</Button>
                    </div>
                    <div className={"flex justify-items-center m-1.5 gap-1"}>
                        <Button
                            onMouseDown={() => handleMouseDown("z", -1)} onMouseUp={handleMouseUp}
                            onClick={() => changeZPosition(z - 1)}>-</Button>
                        <TextInput type="text" value={z}/>
                        <Button
                            onMouseDown={() => handleMouseDown("z", 1)} onMouseUp={handleMouseUp}
                            onClick={() => changeZPosition(z + 1)}>+</Button>
                    </div>

                    <div className={"flex justify-items-left m-1.5 gap-1"}>
                        <Button onClick={savePoIPosition}>Save</Button>
                    </div>
                </div>
            )}

        </>
    );
});

export default PoIPositionEditorWidget;
