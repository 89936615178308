import {UNITY_COMM_OBJECT, UNITY_FUNCTION_NAMES} from "../../config/unity";

interface SendMessage {
    (objectName: string, methodName: string, parameter: string | number | undefined ): void;
}

export class UnityService {

    public static setUnityLanguage = async (
        sendMessage: SendMessage,
        language: string
    ) => {
        sendMessage(UNITY_COMM_OBJECT, UNITY_FUNCTION_NAMES.SET_LANGUAGE, language);
    }

    public static goToPoi = async (
        sendMessage: SendMessage,
        cameraPosition: string
    ) => {
        console.log("cameraPosition", cameraPosition)
        sendMessage(UNITY_COMM_OBJECT, UNITY_FUNCTION_NAMES.GO_TO_POI, cameraPosition);
    }

    public static sendPoIList = async(
        sendMessage: SendMessage,
        poiList: string
    )=> {
        sendMessage(UNITY_COMM_OBJECT, UNITY_FUNCTION_NAMES.SEND_POI_LIST, poiList);
    }

    public static enterPoI = async (
        sendMessage: SendMessage,
        poiId: number
    ) => {
        sendMessage(UNITY_COMM_OBJECT, UNITY_FUNCTION_NAMES.ENTER_POI, poiId);
    }

    public static loadModel = async (
        sendMessage: SendMessage,
        modelURI: string
    ) => {
        sendMessage(UNITY_COMM_OBJECT, UNITY_FUNCTION_NAMES.LOAD_MODEL, modelURI);
    }

    public static onMouseEnterPoi = async (
        sendMessage: SendMessage,
        poiId: number
    ) => {
        sendMessage(UNITY_COMM_OBJECT, UNITY_FUNCTION_NAMES.ON_MOUSE_ENTER_POI, poiId);
    }

    public static onMouseLeavePoi = async (
        sendMessage: SendMessage,
        poiId: number
    ) => {
        sendMessage(UNITY_COMM_OBJECT, UNITY_FUNCTION_NAMES.ON_MOUSE_LEAVE_POI, poiId);
    }

    public static resetCamera = async (
        sendMessage: SendMessage,
    ) => {
        sendMessage(UNITY_COMM_OBJECT, UNITY_FUNCTION_NAMES.RESET_CAMERA, undefined);
    }

    public static setCameraHandleType = async (
        sendMessage: SendMessage,
        cameraType: number
    ) => {
        sendMessage(UNITY_COMM_OBJECT, UNITY_FUNCTION_NAMES.SET_CAMERA_HANDLE_TYPE, cameraType);
    }

    public static getCameraPositions = async (
        sendMessage: SendMessage,
    ) => {
        sendMessage(UNITY_COMM_OBJECT, UNITY_FUNCTION_NAMES.GET_CAMERA_POSITIONS, undefined);
    }

    public static toggleObject = async (
        sendMessage: SendMessage,
        objectName: string
    ) => {
        sendMessage(UNITY_COMM_OBJECT, UNITY_FUNCTION_NAMES.TOGGLE_OBJECT, objectName);
    }

    public static togglePoIEditor = async (
        sendMessage: SendMessage,
        poiId: number
    ) => {
        sendMessage(UNITY_COMM_OBJECT, UNITY_FUNCTION_NAMES.TOGGLE_POI_EDITOR, poiId);
    }

    public static updatePoIMarkerPosition = async (
        sendMessage: SendMessage,
        position: string,
    ) => {
        sendMessage(UNITY_COMM_OBJECT, UNITY_FUNCTION_NAMES.UPDATE_POI_MARKER_POSITION, position);
    }

}

