import {Dropdown} from "flowbite-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLanguage} from "@fortawesome/free-solid-svg-icons";
import {useStore} from "../../../../data/state/store";
import {Instance} from "mobx-state-tree";
import LanguageModel from "../../../../data/state/models/LanguageModel";
import {languages} from "../../../../data/intl/languages";
import {observer} from "mobx-react";

export const LanguageSelector = observer(() => {

    const {languageStore} = useStore();

    const setApplicationLanguage = (language: Instance<typeof LanguageModel>) => {
        languageStore.setLanguage(language);
    }

    return (
        <>

            <Dropdown inline label={
                <>
                    <FontAwesomeIcon icon={faLanguage} size={"lg"} className={"mr-2.5"}/>
                </>
            }>
                {languages.map((language, index) => (
                        <Dropdown.Item key={index} onClick={() => setApplicationLanguage(language)}>
                            {language.name}
                        </Dropdown.Item>
                    ))
                }

            </Dropdown>
        </>
    );
});
