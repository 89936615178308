import {types} from "mobx-state-tree";
import {ModelConstants} from "../models/ModelConstants";

export const GlobalStore = types.model(ModelConstants.GlobalModel, {

    webgl2Supported: types.optional(types.boolean, false),
    deviceScreenOrientation: types.optional(types.string, window.screen.orientation.type),
    appReady: types.optional(types.boolean, false)

})

    .actions(self => ({

        setWebGL2Supported(supported: boolean) {
            self.webgl2Supported = supported;
        },

        setDeviceScreenOrientation(orientation: string) {
            self.deviceScreenOrientation = orientation;
        },

        setAppReady(ready: boolean) {
            self.appReady = ready;
        }

    }));
