export const checkIfWebGL2Supported = () => {
    if (!window.WebGLRenderingContext) {
        return false;
    } else {
        const canvas = document.createElement('canvas');
        const gl = canvas.getContext('webgl') || canvas.getContext('webgl2');
        return !!gl;
    }
}

export const getDeviceScreenOrientation = () => {
    return window.screen.orientation.type;
}
