import React, {useEffect, useState} from 'react';
import {initializeStore, Provider} from "./app/data/state/store";
import {MainScreen} from "./app/source/screens/MainScreen";
import useLogger, {LogLevel} from "./app/source/hooks/Logger";
import "./App.css"
import {Splash} from "./app/source/components/Splash/Splash";

function App() {

    const [rootStore, setRootStore] = useState(null);
    const [ready, setReady] = useState(false);
    const {logger} = useLogger()

    // MST Root Store initialization
    useEffect(() => {
        const prepare = async() => {

            //Initialize store
            const store = await initializeStore();
            setRootStore(store)

            store.globalStore.setAppReady(true)

        }

        prepare().then( () => {

            logger("App ready", LogLevel.INFO);
            logger("Current execution environment: NODE_ENV " + process.env.NODE_ENV, LogLevel.INFO);
            logger("Current REACT_APP_HOME_URL: " + process.env.REACT_APP_HOME_URL, LogLevel.INFO);

            setInterval(() => {
                setReady(true);

            }, +!process.env.REACT_APP_FAKE_LOADING_TIME || 5000);

        });


    }, []);


    return (
        <>
            { !ready &&
                <>
                    <Splash/>
                </>
            }

            { ready &&
                <Provider value={rootStore}>
                    <MainScreen/>
                </Provider>
            }

        </>
    );



}

export default App;
