import {Button} from "flowbite-react";
import {useNavigate} from "react-router-dom";
import {SCREEN_PATHS} from "../../../config/paths";

export const WelcomeScreen = () => {

    const navigate = useNavigate();

    const handleClick = () => {

        navigate(SCREEN_PATHS.VIEWER)
    }

    return (
        <>

            <div className={"flex justify-center justify-items-center"}>

                <h1>Welcome screen</h1>

                <Button onClick={handleClick}>
                    Go to viewer
                </Button>
            </div>


        </>
    );
};
