// @ts-ignore
import defaultLogo from "../../../assets/multimedia/imgs/logos/principal_negativo.svg";
import {Navbar} from "flowbite-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCube} from "@fortawesome/free-solid-svg-icons";
import {LanguageSelector} from "./LanguageSelector/LanguageSelector";


export const TopMenu = () => {

    return (
        <>
            <div className={"py-2.5 z-30 flex items-center justify-between bg-gray-800 text-white w-full"}>

                <Navbar fluid rounded className={"bg-transparent w-full"}>
                    <Navbar.Brand className={"mr-2.5 items-center flex justify-items-center"}>

                        <img src={defaultLogo} className="mr-6 h-6 sm:h-9" alt="TwinHub Logo"/>
                        <span className="self-center items-center flex whitespace-nowrap text-xl font-semibold dark:text-white">
                            <FontAwesomeIcon icon={faCube}/>
                            &nbsp;
                            TwinHub Viewer
                            &nbsp;



                        </span>
                    </Navbar.Brand>

                    <LanguageSelector />

                </Navbar>

            </div>

        </>
    );
};
