import {ModelConstants} from "./ModelConstants";
import {SnapshotIn, types} from "mobx-state-tree";

const LanguageModel = types.model(ModelConstants.LanguageModel, {

    name: types.string,
    isoCode: types.string,
    default: types.boolean


});

export default LanguageModel;

export type LanguageInSnapshot = SnapshotIn<typeof LanguageModel>;
