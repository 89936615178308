import {Breadcrumb} from "flowbite-react";
import {useStore} from "../../../data/state/store";
import {observer} from "mobx-react";
import PoIModel from "../../../data/state/models/PoIModel";
import {Instance} from "mobx-state-tree";
import usePoI from "../../hooks/PoI";
import useLogger, {LogLevel} from "../../hooks/Logger";
import {ReactUnityEventParameter} from "react-unity-webgl/distribution/types/react-unity-event-parameters";
import {useRef} from "react";
import {SceneTitle} from "../3DViewer/SceneTitle/SceneTitle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

interface PoIBreadcrumbProps {

    colSpan?: number,
    rowSpan?: number,
    colStart?: number,
    rowStart?: number,
    stopEventsPropagation?: boolean,
    unityMessenger: (gameObjectName: string, methodName: string, parameter?: ReactUnityEventParameter) => void
}

const PoIBreadcrumb = observer(({colSpan, rowSpan, colStart, rowStart, stopEventsPropagation=false, unityMessenger}: PoIBreadcrumbProps) => {

    const {poiStore, viewStore} = useStore();
    const {selectPoI, goToPoIPosition, sendPoIList, loadPoIModel} = usePoI();
    const {logger} = useLogger();

    const unityMessengerRef = useRef(unityMessenger)
    unityMessengerRef.current = unityMessenger

    const handleClickOnCrumb = (poi: Instance<typeof PoIModel>) => {

        selectPoI(poi)

        // Si la escena actual no es la misma que la del PoI seleccionado, cargamos la escena del PoI
        if ( viewStore.view.currentScene !== poiStore.scenePoI?.asset3DURI ) {

            logger(`Loading new scene for PoI with id: ${poi.id}`, LogLevel.INFO)

            loadPoIModel(unityMessengerRef.current, poiStore.scenePoI!).then(r => {})
        } else {
            goToPoIPosition(unityMessengerRef.current, poi.cameraPosition, poi.cameraTargetPosition).then(r => {

            })

            // Si el PoI no carga su propia escena, enviamos los marcadores que se representan en la escena actual, salvo
            // que el PoI seleccionado sea el PoI raíz.
            if (!poi.asset3DURI || (poi.id === poiStore.tree?.id)) {
                sendPoIList(unityMessengerRef.current, poi).then(r => {
                })
            }

        }
    }

    const handleMouseEvents = (event: any) => {

        if (stopEventsPropagation) {
            logger("Stopping event propagation", LogLevel.INFO)
            event.stopPropagation();
            event.preventDefault();
        }
    };

    return (
        <>
            <div
                onMouseDown={(event) => handleMouseEvents(event)}
                onWheel={(event) => handleMouseEvents(event)}
                onContextMenu={(event) => handleMouseEvents(event)}
                onMouseUp={(event) => handleMouseEvents(event)}
                id={"poi-breadcrumb"}
                style={{
                    pointerEvents: "auto",
                    gridColumnStart: colStart,
                    gridColumnEnd: `span ${colSpan}`,
                    gridRowStart: rowStart,
                    gridRowEnd: `span ${rowSpan}`,
                }}>


                <div className={"m-2.5"}>
                    <SceneTitle
                        title={poiStore.scenePoI?.name! || ""}/>

                </div>
                <Breadcrumb className="m-1.5">
                    {poiStore.poiBreadcrumb.map((poi, index) => (

                        <Breadcrumb.Item
                            onClick={(event) => handleClickOnCrumb(poi)}
                            className={"select-none items-center text-white hover:cursor-pointer bg-gray-800 dark:bg-gray-800 p-1.5"}
                            key={index}>
                            {poi.id === poiStore.tree?.id && <FontAwesomeIcon className={"mr-1.5"} icon={faHome}/>}
                            {poi.name}
                        </Breadcrumb.Item>
                    ))}
                </Breadcrumb>


            </div>
        </>
    );
});

export default PoIBreadcrumb;
