import {CSSTransition} from "react-transition-group";
import {SPLASH_LOGO} from "../../../config/global";
import {useState} from "react";
import "./styles.css"
import {Footer} from "flowbite-react";

export const Splash = () => {

    const [ready] = useState(true)

    return (
        <>

            <CSSTransition

                in={ready}
                timeout={500}
                classNames="splash">

                <div className={"h-full w-full justify-center items-center flex"}>

                    <img src={SPLASH_LOGO} alt="logo" className="logo w-2/4"/>

                </div>

            </CSSTransition>

            <Footer className={"w-full"}>
                <div className="text-center p-6 text-xs text-gray-500 justify-items-center w-full items-center flex">
                    © {new Date().getFullYear()} All rights reserved. Powered by&nbsp;<a href="https://signalsoftware.es" className="text-blue-500">Signal Software</a>
                </div>
            </Footer>

        </>
    );
};
