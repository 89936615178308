import {API_BASE_URL} from "../../config/services";
import axios from "axios";
import {PoIDataUpdate} from "../types/poi";

interface PoIParams {

    id: number,
    es_pa_carlos?: boolean,
    max_depth?: number,
}

export const getPoIById = async ({id, es_pa_carlos, max_depth}: PoIParams) => {

    try {
        const response = await axios.get(`${API_BASE_URL}/poi/${id}`,
            {
                params: {
                    es_pa_carlos,
                    max_depth
                }
            });

        return response.data;
    } catch (error) {
        console.error(`Error obteniendo el PoI con id ${id}`, error);
        throw error;
    }
}

export const savePoIData = async (poiId: number, poiData: PoIDataUpdate) => {


    try {
        const response = await axios.put(`${API_BASE_URL}/poi/${poiId}/data`, poiData);
        console.log(response.data)
        return response.data;
    } catch (error) {
        console.error(`Error guardando los datos del PoI con id ${poiId}`, error);
        throw error;
    }

}
