import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import "leaflet/dist/leaflet.css"
import {observer} from "mobx-react";
import {useEffect, useRef, useState} from "react";
import {useStore} from "../../../data/state/store";
import {INITIAL_MAP_ZOOM} from "../../../config/global";
import {Icon} from "leaflet";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faExpand
} from "@fortawesome/free-solid-svg-icons";
import "./styles.css"
import usePoI from "../../hooks/PoI";
import useLogger, {LogLevel} from "../../hooks/Logger";

interface PoIMinimapWidgetProps {

    colSpan?: number,
    rowSpan?: number,
    colStart?: number,
    rowStart?: number,
    unityMessenger: (gameObjectName: string, methodName: string, parameter?: any) => void
}


const PoIMinimapWidget = observer((
    {colSpan, rowSpan, colStart, rowStart, unityMessenger}: PoIMinimapWidgetProps
) => {

    const unityMessengerRef = useRef(unityMessenger)
    unityMessengerRef.current = unityMessenger

    const [size, setSize] = useState("s")
    const {poiStore, minimapStore, viewStore} = useStore()
    const [position, setPosition] =
        useState<{ lat: number, lng: number } | undefined>(undefined)

    const icon = new Icon({
        iconUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png',
    })
    const {logger} = useLogger()
    const {selectPoI, goToPoIPosition, sendPoIList} = usePoI();

    const handleOnMouseDown = (event: any) => {
        event.originalEvent.stopPropagation()
    }

    const handleClickOnMarker = (poi: any) => {

        selectPoI(poi)


        goToPoIPosition(unityMessengerRef.current,
            poi.cameraPosition, poi.cameraTargetPosition).then(r => {
            logger("PoI position set", LogLevel.INFO)
        })

        sendPoIList(unityMessengerRef.current, poi).then(r => {
            logger("PoI list sent", LogLevel.INFO)
        })

    }

    useEffect(() => {

        if (poiStore.scenePoI?.lat && poiStore.scenePoI?.lon)
            setPosition({lat: poiStore.scenePoI?.lat, lng: poiStore.scenePoI?.lon})

    }, [poiStore.scenePoI?.lat, poiStore.scenePoI?.lon]);

    return (
        <>

        {position?.lat && position?.lng && size !== "none" && viewStore.view.showMinimap && (

            <>

                {}
                <div
                    className={"m-1.5"}
                    style={{
                        pointerEvents: "auto",
                        gridColumnStart: colStart,
                        gridColumnEnd: `span ${colSpan}`,
                        gridRowStart: rowStart,
                        gridRowEnd: `span ${rowSpan}`,
                }}>

                    <MapContainer
                        scrollWheelZoom={true}
                        className={"bg-transparent border-blue-900 border-2 w-full h-full"}
                        center={position} zoom={INITIAL_MAP_ZOOM}
                        placeholder={<div className={"justify-items-end items-center select-none"}>
                            <div role="status" className={"max-w-sm animate-pulse items-center"}>
                                <div
                                    className={"h-2.5 bg-gray-700 rounded-full dark:bg-gray-700 w-48 mt-1.5"}></div>
                            </div>
                        </div>}>

                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>

                        {minimapStore.minimap.markers && minimapStore.minimap.markers.map((poi) => (
                            <>
                                {
                                    poi.lat && poi.lon && (
                                        <>
                                            <Marker
                                                eventHandlers={{
                                                    click: () => handleClickOnMarker(poi)
                                                }}
                                                icon={icon}
                                                position={{lat: poi.lat, lng: poi.lon}}>
                                                <Popup>
                                                    {poi.name}
                                                </Popup>
                                            </Marker>
                                        </>
                                    )}
                            </>
                        ))}

                    </MapContainer>

                </div>


            </>
        )
        }
        </>
    )
});

export default PoIMinimapWidget;
