import {Unity} from "react-unity-webgl";
import {
    UNITY_CANVAS_ID,
    UNITY_FUNCTION_LISTENERS,
    WIDGETS_GRID_ID
} from "../../../config/unity";
import {useCallback, useEffect, useRef, useState} from "react";
import {getPoIById, savePoIData} from "../../../data/services/poiService";
import {ROOT_POI_ID, SHOW_LEAF_POI_MARKER} from "../../../config/global";
import {PoIDataUpdate} from "../../../data/types/poi";
import {useStore} from "../../../data/state/store";
import "./styles.css";
import {UnityProvider} from "react-unity-webgl/distribution/types/unity-provider";
import PoIBreadcrumb from "../PoIBreadcrumb/PoIBreadcrumb";
import PoIWeatherWidget from "../PoIWeatherWidget/PoIWeatherWidget";
import {clone, Instance} from "mobx-state-tree";
import {
    getPoIAscendants,
    getPoIFromTreeById, poiAPIToPoIModel
} from "../../../utils/poiUtils";
import {ReactUnityEventParameter} from "react-unity-webgl/distribution/types/react-unity-event-parameters";
import useLogger, {LogLevel} from "../../hooks/Logger";
import {observer} from "mobx-react";
import PoIMinimapWidget from "../PoIMinimapWidget/PoIMinimapWidget";
import usePoI from "../../hooks/PoI";
import ThreeDToolset from "../ThreeDToolset/ThreeDToolset";
import PoIModel from "../../../data/state/models/PoIModel";
import PoISensorInfoWidget from "../PoISensorInfoWidget/PoISensorInfoWidget";
import PoIPositionEditorWidget from "../PoIPositionEditorWidget/PoIPositionEditorWidget";

interface ThreeDViewerProps {
    unityProvider: UnityProvider,
    addEventListener: (eventName: string, callback: (event: any) => void) => void
    removeEventListener: (eventName: string, callback: (event: any) => void) => void,
    unityMessenger: (gameObjectName: string, methodName: string, parameter?: ReactUnityEventParameter) => void
}

const COMPONENT_NAME = "ThreeDViewer"

export const ThreeDViewer = observer(({
                                 unityProvider,
                                 addEventListener,
                                 removeEventListener,
                                 unityMessenger
                             }: ThreeDViewerProps) => {

    const {poiStore, viewStore} = useStore();
    const {logger} = useLogger();
    const {selectPoI, loadPoIModel, sendPoIList, goToPoIPosition, removeMarkers, sendPoIMarker} = usePoI();
    const [cursorWithinCanvas, setCursorWithinCanvas] = useState(false);
    const unityMessengerRef = useRef(unityMessenger)

    unityMessengerRef.current = unityMessenger

    const handleGetCameraPositions = useCallback((data: ReactUnityEventParameter) => {

        logger("Getting camera positions: " + data, LogLevel.INFO, COMPONENT_NAME)

        if (data === null || data === undefined) return;

        if (typeof data === "string") {

            const cameraPositions = JSON.parse(data)

            let positions: PoIDataUpdate

            if (!poiStore.saveStarterPosition) {
                logger("Saving camera and target position", LogLevel.INFO, COMPONENT_NAME)
                positions = {
                    label: poiStore.selectedPoI?.name!,
                    camera_position: {
                        x: cameraPositions.camera_position.x,
                        y: cameraPositions.camera_position.y,
                        z: cameraPositions.camera_position.z
                    },
                    camera_target: {
                        x: cameraPositions.camera_target.x,
                        y: cameraPositions.camera_target.y,
                        z: cameraPositions.camera_target.z
                    },
                    poi_position: {
                        x: cameraPositions.camera_target.x,
                        y: cameraPositions.camera_target.y+15,
                        z: cameraPositions.camera_target.z
                    },
                    poi_assets: {}
                } as PoIDataUpdate
            }

            else {
                logger("Saving starter camera position and target", LogLevel.INFO, COMPONENT_NAME)
                positions = {

                    label: poiStore.selectedPoI?.name!,

                    camera_position: null,
                    camera_target: null,
                    poi_position: null,

                    poi_assets: {
                        starter_camera_target_x_position: cameraPositions.camera_target.x,
                        starter_camera_target_y_position: cameraPositions.camera_target.y,
                        starter_camera_target_z_position: cameraPositions.camera_target.z,

                        starter_camera_x_position: cameraPositions.camera_position.x,
                        starter_camera_y_position: cameraPositions.camera_position.y,
                        starter_camera_z_position: cameraPositions.camera_position.z
                    }
                } as PoIDataUpdate
            }

            savePoIData(poiStore.selectedPoI?.id!, positions).then(r =>
                logger("Camera positions saved: " + r, LogLevel.INFO, COMPONENT_NAME)
            )
        }

    }, [poiStore.saveStarterPosition])

    const handleClickOnPoI = useCallback((idPoI: ReactUnityEventParameter) => {

        logger("Click on PoI: " + idPoI, LogLevel.INFO, COMPONENT_NAME)

        let parsedIdPoI: number | null = Number(idPoI)

        if (!isNaN(parsedIdPoI)) {

            const found_poi = getPoIFromTreeById(poiStore.tree!, parsedIdPoI)

            if (found_poi?.id) {
                logger("Found PoI with id: " + found_poi.id, LogLevel.INFO, COMPONENT_NAME)
                selectPoI(found_poi)


                goToPoIPosition(unityMessengerRef.current,
                    found_poi.cameraPosition, found_poi.cameraTargetPosition).then(r => {
                    logger("PoI position set", LogLevel.INFO, COMPONENT_NAME)
                })

                if (!found_poi.asset3DURI || !found_poi.loadsNewScene || (found_poi.id === poiStore.tree?.id)) {
                    sendPoIList(unityMessengerRef.current, found_poi).then(() => {
                        logger(`Asked 3D Viewer to send PoI list for PoI with id: ${found_poi.id}`, LogLevel.INFO, COMPONENT_NAME)
                    })
                }
                else {

                    if (SHOW_LEAF_POI_MARKER) {
                        sendPoIMarker(unityMessengerRef.current, found_poi).then(() => {
                            logger(`Asked 3D Viewer to send PoI marker for PoI with id: ${found_poi.id}`, LogLevel.INFO, COMPONENT_NAME)
                        })
                    } else {
                        removeMarkers(unityMessengerRef.current).then(() => {
                            logger("Asked 3D Viewer to remove markers", LogLevel.INFO, COMPONENT_NAME)
                        })
                    }
                }

            } else {
                logger("PoI not found", LogLevel.ERROR)
                return;
            }
        } else {
            logger("Invalid PoI id", LogLevel.ERROR)
            return;
        }


    }, [unityMessenger])

    const handleOnViewerLoaded = () => {
        logger("3D Viewer loaded", LogLevel.INFO, COMPONENT_NAME)
        viewStore.setViewerLoaded(true)
        viewStore.setCurrentScene(poiStore.scenePoI?.asset3DURI)
    }

    const handleOnSceneLoaded = (data: ReactUnityEventParameter) => {

        // Marcamos la escena como ya cargada en el estado.
        viewStore.setSceneLoaded(true)
        logger(`Scene loaded: ${poiStore.scenePoI?.asset3DURI}`, LogLevel.INFO, COMPONENT_NAME)

        // Establecemos la escena actual en el estado. Siempre se hará una carga del PoI marcado como PoI de escena.
        viewStore.setCurrentScene(poiStore.scenePoI?.asset3DURI)

        // ENVÍO DE MARCADORES PARA LA ESCENA.
        // Con la nueva escena cargada, hemos de enviar los marcadores del PoI seleccionad, en los siguientes casos:
        // 1: Que el PoI sea el PoI raíz.
        // 2: Que el PoI seleccionado NO cargue nueva escena, ya que sus PoIs hijos se representarían en otra escena.
        // 3: Que el PoI seleccionado SÍ cargue nueva escena, y tengamos que enviar los marcadores hijos.
        if (!poiStore.selectedPoI?.loadsNewScene || poiStore.selectedPoI?.id === poiStore.tree?.id ||
            (poiStore.selectedPoI.id === poiStore.scenePoI?.id)) {
            logger("Selected PoI does not load new scene or is the root PoI, sending PoI list", LogLevel.INFO, COMPONENT_NAME)
            sendPoIList(unityMessengerRef.current, poiStore.selectedPoI!).then(r => {
                logger("Scene PoI list sent", LogLevel.INFO, COMPONENT_NAME)
            })
        } else {
            // En caso de que el PoI seleccionado NO haya de enviar marcadores, no tenemos que mostrar marcadores
            // en la escena, los borramos.
            logger("Selected PoI loads its own scene or is the root PoI, not sending PoI list", LogLevel.WARNING)
            removeMarkers(unityMessengerRef.current).then(r => {
                logger("Markers removed from scene", LogLevel.INFO, COMPONENT_NAME)
            })
        }

        // POSICIÓN INICIAL DE LA CÁMARA.
        // Establecemos la posición inicial de la cámara tras cargar la escena.
        let cameraPosition = {}
        let cameraTargetPosition = {}

        // Si la escena cargada es propia del PoI seleccionado, debemos cargar la posición inicial, o starter position.
        if (poiStore.scenePoI?.id === poiStore.selectedPoI?.id) {
            logger("Scene PoI is the selected PoI, going to its starter position", LogLevel.INFO, COMPONENT_NAME)
            cameraPosition = poiStore.selectedPoI?.starterCameraPosition
            cameraTargetPosition = poiStore.selectedPoI?.starterCameraTargetPosition

        // Si la escena cargada no es la del PoI seleccionado, la posición inicial corresponde a la posición del marcador
        // del PoI seleccionado
        } else {
            logger("Scene PoI is not the selected PoI, going to its marker position", LogLevel.INFO, COMPONENT_NAME)
            cameraPosition = poiStore.selectedPoI?.cameraPosition
            cameraTargetPosition = poiStore.selectedPoI?.cameraTargetPosition
        }

        // Enviamos la posición de la cámara al visor.
        goToPoIPosition(unityMessengerRef.current,
            cameraPosition, cameraTargetPosition).then(r => {
            logger("Scene PoI position set", LogLevel.INFO, COMPONENT_NAME)
        })

    }

    const loadRootPoI = () => {

        if (poiStore.tree && poiStore.tree.id) // Si el PoI raíz ya ha sido cargado, no lo cargamos de nuevo
            return

        const params = {
            id: ROOT_POI_ID,
            max_depth: undefined
        }

        getPoIById(params).then((response) => {

            const tree: Instance<typeof PoIModel> = poiAPIToPoIModel(response);

            poiStore.setPoITree(tree); // Establecemos el PoI raíz
            selectPoI(poiStore.tree!) // Seleccionamos el PoI raíz
            viewStore.setRelativePoIDepth(tree.depth) // Establecemos la profundidad relativa del PoI raíz

            if (poiStore.tree && poiStore.selectedPoI) // Establecemos el breadcrumb si el PoI raíz y el PoI seleccionado existen
                poiStore.setPoIBreadcrumb(getPoIAscendants(poiStore.tree, poiStore.selectedPoI))

            // Adicionalmente, cargaremos el PoI raíz como PoI de escena si tiene assets 3D
            if (tree.loadsNewScene) {
                poiStore.setScenePoI(clone(poiStore.tree))

                if (tree.asset3DURI) {
                    logger("Root PoI loads new scene: " + tree.asset3DURI, LogLevel.INFO, COMPONENT_NAME)
                    viewStore.setSceneURI(tree.asset3DURI)
                    loadPoIModel(unityMessengerRef.current, tree).then(r => {
                        logger("Root PoI model loaded", LogLevel.INFO, COMPONENT_NAME)
                    });
                }
            }
        });
    }

    // Listeners
    useEffect(() => {

        const unityCanvas = document.getElementById(UNITY_CANVAS_ID)

        const onEnter = () =>  {
            setCursorWithinCanvas(true);
        }
        const onLeave = () => {
            setCursorWithinCanvas(false);
        }
        const handleMouseEvents = (event: any) => {

            if (!cursorWithinCanvas) {
                event.stopPropagation();
                event.preventDefault();
            }
        };

        if (unityCanvas) {
            unityCanvas.addEventListener('mouseenter', onEnter);
            unityCanvas.addEventListener('mouseleave', onLeave);
            unityCanvas.addEventListener("contextmenu", (event) => { event.preventDefault() })

            document.addEventListener('mousedown', handleMouseEvents, true);
            document.addEventListener('mouseup', handleMouseEvents, true);
            document.addEventListener('mousemove', handleMouseEvents, true);

        }

        // Listeners

        addEventListener(UNITY_FUNCTION_LISTENERS.ON_VIEWER_LOADED, handleOnViewerLoaded)
        addEventListener(UNITY_FUNCTION_LISTENERS.ON_SCENE_LOADED, handleOnSceneLoaded)
        addEventListener(UNITY_FUNCTION_LISTENERS.ON_CAMERA_POSITION_REQUEST, handleGetCameraPositions)
        addEventListener(UNITY_FUNCTION_LISTENERS.ON_CLICK_ON_POI, handleClickOnPoI)

        return () => {

            if (unityCanvas) {
                unityCanvas.removeEventListener('mouseenter', onEnter);
                unityCanvas.removeEventListener('mouseleave', onLeave);
            }

            document.removeEventListener('mousedown', handleMouseEvents, true);
            document.removeEventListener('mouseup', handleMouseEvents, true);
            document.removeEventListener('mousemove', handleMouseEvents, true);

            removeEventListener(UNITY_FUNCTION_LISTENERS.ON_VIEWER_LOADED, handleOnViewerLoaded)
            removeEventListener(UNITY_FUNCTION_LISTENERS.ON_SCENE_LOADED, handleOnSceneLoaded)
            removeEventListener(UNITY_FUNCTION_LISTENERS.ON_CAMERA_POSITION_REQUEST, handleGetCameraPositions)
        }

    }, [addEventListener, removeEventListener, handleGetCameraPositions, cursorWithinCanvas])

    // Este effect se ejecutará cuando se cargue el visor
    useEffect(() => {

        // Una vez el visor esté cargado y haya un URI que cargar...
        if (viewStore.view.viewerLoaded) {
            logger("3D viewer loaded", LogLevel.INFO, COMPONENT_NAME)
            loadRootPoI()
        }

    }, [viewStore.view.viewerLoaded, unityMessenger, unityProvider])


    return (
        <>
            <Unity
                id={UNITY_CANVAS_ID}
                matchWebGLToCanvasSize={true}
                className={"h-full max-w-full"}
                unityProvider={unityProvider}/>

            <div id={WIDGETS_GRID_ID}
                 style={{pointerEvents: "none"}}
                 className={"grid top-0 left-0 grid-cols-12 grid-rows-12 absolute gap-3 w-full h-full"}>

                <PoIBreadcrumb colSpan={10} rowSpan={1} colStart={1} rowStart={1} unityMessenger={unityMessenger}/>

                <ThreeDToolset colSpan={1} rowSpan={11} colStart={1} rowStart={3} unityMessenger={unityMessenger}/>

                <PoIWeatherWidget colSpan={1} rowSpan={3} colStart={12} rowStart={1}/>

                <PoIMinimapWidget colSpan={3} rowSpan={3} colStart={10} rowStart={9} unityMessenger={unityMessenger}/>

                <PoISensorInfoWidget colSpan={3} rowSpan={5} colStart={2} rowStart={7} unityMessenger={unityMessenger}/>

                <PoIPositionEditorWidget colSpan={2} rowSpan={4} colStart={2} rowStart={9} unityMessenger={unityMessenger}/>

            </div>

        </>
    );
});
