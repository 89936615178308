import PoIModel from "../data/state/models/PoIModel";
import {clone, Instance} from "mobx-state-tree";

import {PoI, PoI3DViewer} from "../data/types/poi";

export const getPoIAscendants = (tree: Instance<typeof PoIModel>, poi: Instance<typeof PoIModel>, includeChild: boolean = true): Instance<typeof PoIModel>[] => {

    let ascendants: Instance <typeof PoIModel>[] = [];

    const traverse = (node: Instance<typeof PoIModel>) => {
        if (node.lft < poi.lft && node.rgt > poi.rgt) {
            ascendants.push(clone(node));
        }

        if (node.children) {
            for (let child of node.children) {
                traverse(child);
            }
        }
    };

    traverse(tree);

    // Adding the selected PoI to the breadcrumb
    if (includeChild)
        ascendants.push(clone(poi));

    return ascendants;
}

export const getParentScenePoI = (tree: Instance<typeof PoIModel>, poi: Instance<typeof PoIModel>): Instance<typeof PoIModel> | undefined => {

        let ascendants: Instance<typeof PoIModel>[] = getPoIAscendants(tree, poi, false);

        if (!ascendants || ascendants.length === 0) return;

        ascendants = ascendants.reverse();

        for (let ascendant of ascendants) {
            if (ascendant.loadsNewScene) {
                return ascendant;
            }
        }
        return undefined;
}


export const convertPoiListTo3DViewer = (poiList: Instance<typeof PoIModel>[]) => {

    let converted_poi_list: PoI3DViewer[] = [];
    poiList.forEach( poi => {
        converted_poi_list.push(poiModelToPoI3DViewer(poi))
    })

    // Primero se convierte la lista de PoIs a un formato que pueda ser interpretado por el visor 3D
    let poisToViewer = { "POIs": converted_poi_list };

    // Segundo lo pasamos a string y lo devolvemos
    return JSON.stringify(poisToViewer)

}

export const convertPoIListToMarkers = (poiList: any[]) => {

    let markers: Instance<typeof PoIModel>[] = [];

    poiList.forEach( poi => {
        markers.push(poiAPIToPoIModel(poi))
    })

    return markers;
}

export const getPoIFromTreeById = (tree: Instance<typeof PoIModel>, id: number): Instance<typeof PoIModel> | undefined => {

    let poi: Instance<typeof PoIModel> | undefined = undefined;

    const traverse = (node: Instance<typeof PoIModel>) => {
        if (node.id === id) {
            poi = clone(node);
        }

        if (node.children) {
            for (let child of node.children) {
                traverse(child);
            }
        }
    };

    traverse(tree);

    return poi;

}

export const poiAPIToPoIModel = (poiAPI: any): Instance<typeof PoIModel> => {

    const poiModel= PoIModel.create({
        id: poiAPI.id,
        entityId: poiAPI.entity_id?poiAPI.entity_id:undefined,

        // TYPE
        type: poiAPI.poi_type?.code?poiAPI.poi_type?.code:undefined,
        typeId: poiAPI.poi_type?.id?poiAPI.poi_type?.id:undefined,

        // INFO
        name: poiAPI.poi_info?.name?poiAPI.poi_info?.name:undefined,
        description: poiAPI.poi_info?.description?poiAPI.poi_info?.description:undefined,
        media: poiAPI.poi_info?.media,
        lat: poiAPI.poi_info?.lat,
        lon: poiAPI.poi_info?.lon,

        // DATA
        dataId: poiAPI.poi_data?.id,
        cameraPosition: poiAPI.poi_data? {
            x: poiAPI.poi_data.camera_position?.x, y: poiAPI.poi_data.camera_position?.y, z: poiAPI.poi_data.camera_position?.z,
            }: undefined,

        cameraTargetPosition: poiAPI.poi_data? {
            x: poiAPI.poi_data.camera_target?.x, y: poiAPI.poi_data.camera_target?.y, z: poiAPI.poi_data.camera_target?.z,
            }: undefined,

        position: poiAPI.poi_data? {
            x: poiAPI.poi_data.poi_position?.x, y: poiAPI.poi_data.poi_position?.y, z: poiAPI.poi_data.poi_position?.z,
            }: undefined,

        starterCameraPosition: poiAPI.poi_data? {
            x: poiAPI.poi_data.poi_assets.starter_camera_x_position, y: poiAPI.poi_data.poi_assets.starter_camera_y_position, z: poiAPI.poi_data.poi_assets.starter_camera_z_position
            }: undefined,

        starterCameraTargetPosition: poiAPI.poi_data? {
            x: poiAPI.poi_data.poi_assets.starter_camera_target_x_position, y: poiAPI.poi_data.poi_assets.starter_camera_target_y_position, z: poiAPI.poi_data.poi_assets.starter_camera_target_z_position
            }: undefined,

        label: poiAPI.poi_data?.label,
        loadsNewScene: !!poiAPI.poi_data?.poi_assets?.uri,
        asset3DURI: poiAPI.poi_data?.poi_assets?.uri?poiAPI.poi_data?.poi_assets?.uri:undefined,

        // OU
        depth: poiAPI.depth,
        lft: poiAPI.lft,
        rgt: poiAPI.rgt,



        children: []
    });

    if (poiAPI.children) {
        poiAPI.children.forEach((childPoi: any) => {
            const childPoiModel = poiAPIToPoIModel(childPoi);
            poiModel.pushChild(childPoiModel);
        });
    }

    return poiModel;
}

export const poiModelToPoI3DViewer = (poi: Instance <typeof PoIModel>): PoI3DViewer => {

    return {

        id: poi.id,
            poi_type: {
                id: poi.typeId?poi.typeId:null,
                code: poi.type?poi.type:null,
                prefab_ref: poi.type?poi.type:null,
        },
        poi_data: {
                id: poi.dataId?poi.dataId:null,
                label: poi.label?poi.label:null,
                camera_position: {
                    x: poi.cameraPosition?.x,
                    y: poi.cameraPosition?.y,
                    z: poi.cameraPosition?.z
                },
                camera_target: {
                    x: poi.cameraTargetPosition?.x,
                    y: poi.cameraTargetPosition?.y,
                    z: poi.cameraTargetPosition?.z
                },
                poi_position: {
                    x: poi.position?.x,
                    y: poi.position?.y,
                    z: poi.position?.z
                },

        },
        poi_assets: {
            uri: poi.asset3DURI?poi.asset3DURI:null,
            starterCameraXPosition: 0,
            starterCameraYPosition: 0,
            starterCameraZPosition: 0,
            starterCameraTargetXPosition: 0,
            starterCameraTargetYPosition: 0,
            starterCameraTargetZPosition: 0
        }
    }
}

export const poiAPIToPoI = (apiData: any): PoI => {

    return {
        id: apiData.id,
        lft: apiData.lft,
        rgt: apiData.rgt,
        depth: apiData.depth,
        name: apiData.poi_info.name,
        description: apiData.poi_info.description,
        entityId: apiData.entity_id?apiData.entity_id:undefined,
        lat: apiData.poi_info.lat,
        lon: apiData.poi_info.lon,
        type: apiData.poi_type.code,
        loadsNewScene: apiData.poi_data.poi_assets?!!apiData.poi_data.poi_assets.uri:false,
        asset3DURI: apiData.poi_data.poi_assets?apiData.poi_data.poi_assets.uri:undefined,
        media: apiData.poi_info?.media.map((media: any) => { return {url: media.url, name: media.name, id: media.id}}),
        children: apiData.children ? apiData.children.map(poiAPIToPoI) : [],
        cameraPosition: {
            camera_position: { x: apiData.poi_data.camera_position.x, y: apiData.poi_data.camera_position.y, z: apiData.poi_data.camera_position.z},
            camera_target: { x: apiData.poi_data.camera_target.x, y: apiData.poi_data.camera_target.y, z: apiData.poi_data.camera_target.z}
        }
    };

}

export const poiModelToPoI = (poiModel: Instance<typeof PoIModel>): PoI => {

    return {
        id: poiModel.id,
        lft: poiModel.lft,
        rgt: poiModel.rgt,
        depth: poiModel.depth,
        entityId: poiModel.entityId,
        name: poiModel.name,
        media: poiModel.media?.map((media) => { return {url: media.url, name: media.name, id: media.id}}),
        description: poiModel.description,
        lat: poiModel.lat,
        lon: poiModel.lon,
        type: poiModel.type,
        loadsNewScene: poiModel.loadsNewScene,
        asset3DURI: poiModel.asset3DURI,
        children: poiModel.children?.map(poiModelToPoI),
        cameraPosition: {
            camera_position: {x: poiModel.cameraPosition.camera_position[0], y: poiModel.cameraPosition.camera_position[1], z: poiModel.cameraPosition.camera_position[2]},
            camera_target: {x: poiModel.cameraPosition.camera_target[0], y: poiModel.cameraPosition.camera_target[1], z: poiModel.cameraPosition.camera_target[2]}
        }

    };
}
