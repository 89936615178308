
export const ModelConstants = {

    GlobalModel: "GlobalModel",
    LanguageModel: "LanguageModel",
    PoIModel: "PoIModel",
    PoIMediaModel: "PoIMediaModel",
    PoITypeModel: "PoITypeModel",
    ViewModel: "ViewModel",
    InfoWeatherModel: "InfoWeatherModel",
    MinimapModel: "MinimapModel",
    NavigationPanelTabModel: "NavigationPanelTabModel",

}
