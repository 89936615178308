import {types} from "mobx-state-tree";
import {ModelConstants} from "../models/ModelConstants";
import MinimapModel from "../models/MinimapModel";

export const MinimapStore = types.model(ModelConstants.MinimapModel, {
    minimap: MinimapModel
})

.actions(self => ({

    setMarkers(markers: any) {

        self.minimap.markers = markers;
    }

}));
