import {ModelConstants} from "./ModelConstants";
import {types} from "mobx-state-tree";

const PoIMediaModel = types.model(ModelConstants.PoIMediaModel, {

    id: types.identifierNumber,
    name: types.optional(types.string , ""),
    url: types.optional(types.string, ""),

})
    .actions(self => ({



    }));



export default PoIMediaModel;
