import {ModelConstants} from "./ModelConstants";
import {types} from "mobx-state-tree";
import PoIModel from "./PoIModel";

const MinimapModel = types.model(ModelConstants.MinimapModel, {

    // 3d scene
    markers: types.maybe(types.array(PoIModel))


});

export default MinimapModel;
