import {IntlProvider} from "react-intl";
import {Messages} from "../../utils/languageMessages";
import React, {useEffect} from "react";
import {useStore} from "../../data/state/store";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {SCREEN_PATHS} from "../../config/paths";
import {checkIfWebGL2Supported, getDeviceScreenOrientation} from "../../utils/deviceUtils";
import useLogger, {LogLevel} from "../hooks/Logger";
import {Viewer} from "./viewer/Viewer";
import {MAYOR_MODE} from "../../config/global";
import {WelcomeScreen} from "./welcome/WelcomeScreen";
import {observer} from "mobx-react";


const baseUrl = process.env.REACT_APP_BASE_URL || "/";

export const MainScreen = observer(() => {

    const {languageStore, globalStore, viewStore} = useStore();
    const {logger} = useLogger()

    useEffect(() => {

        //Check device compatibility and orientation
        globalStore.setWebGL2Supported(checkIfWebGL2Supported())
        logger("WebGL2 supported: " + globalStore.webgl2Supported, LogLevel.INFO)
        globalStore.setDeviceScreenOrientation(getDeviceScreenOrientation())
        logger("Device screen orientation: " + globalStore.deviceScreenOrientation, LogLevel.INFO)

        if (globalStore.deviceScreenOrientation === "portrait-primary") {
            viewStore.setPoINavigationDrawer(true)
            logger("Device screen orientation is not portrait", LogLevel.INFO)
        }


    }, []);


    return (
        <>
            <IntlProvider
                locale={languageStore.language.isoCode}
                messages={Messages[languageStore.language.isoCode]}>

                <BrowserRouter basename={baseUrl}>
                    <Routes>
                        {MAYOR_MODE ? (
                                <Route path={"/"} element={<WelcomeScreen/>}/>
                            ):
                            (
                                <Route path={"/"} element={<div>Home</div>}/>
                            )

                        }

                        <Route path={SCREEN_PATHS.VIEWER} element={<Viewer/>}/>

                    </Routes>

                </BrowserRouter>

            </IntlProvider>
        </>
    );
});
