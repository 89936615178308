import {getSnapshot, Instance, types} from "mobx-state-tree";
import {ModelConstants} from "../models/ModelConstants";
import PoIModel from "../models/PoIModel";
import {PoI} from "../../types/poi";
import InfoWeatherModel from "../models/InfoWeatherModel";

export const PoIStore = types.model(ModelConstants.PoIModel, {
    tree: types.maybe(PoIModel),
    selectedPoI: types.maybe(PoIModel),
    scenePoI: types.maybe(PoIModel),
    poiBreadcrumb: types.optional(types.array(PoIModel), []),

    // PROVISIONAL

    saveStarterPosition: types.optional(types.boolean, false)

})

    .actions(self => ({

        setSaveStarterPosition(save: boolean) {

            self.saveStarterPosition = save

        },

        setPoITree(tree: Instance<typeof PoIModel>) {
            //console.log(getSnapshot(tree))
            self.tree = tree
        },

        setSelectedPoI(poi: Instance<typeof PoIModel> | undefined) {

            if (self.selectedPoI?.id === poi?.id) return

            self.selectedPoI = poi
        },

        setScenePoI(poi: Instance<typeof PoIModel> | undefined) {

            if (self.scenePoI?.id === poi?.id) return

            self.scenePoI = poi
        },

        setPoIBreadcrumb(poiList: PoI[]) {
            this.resetPoIBreadcrumb()
            poiList.forEach(poi => this.addPoIBreadcrumb(PoIModel.create(poi)))
        },

        addPoIBreadcrumb(poiBreadcrumb: Instance<typeof PoIModel>) {
            self.poiBreadcrumb.push(poiBreadcrumb)
        },

        resetPoIBreadcrumb(){
            self.poiBreadcrumb.clear()
        },

        setPoIInfoWeather(poi: Instance<typeof PoIModel>, data: any) {

            poi.infoWeather = InfoWeatherModel.create({
                temp: data.main?.temp,
                humidity: data.main?.humidity,
                pressure: data.main?.pressure,
                windSpeed: data.wind?.speed,
                clouds: data.clouds?.all
            });

        },

        setPoIPosition(poi: Instance<typeof PoIModel>, x: number, y: number, z: number) {
            poi.position = {x: x, y: y, z: z}
        }

    }));
