import React, { useState, useEffect, useRef } from 'react';
import {CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis, Tooltip, Legend} from "recharts";


// Define la interfaz para los puntos de datos
interface DataPoint {
    measured_at?: string; // Cambiado a string para reflejar el nuevo formato
    created_at?: string;
    unit: string;
    value: number;
}

interface SensorChartProps {
    measurements: DataPoint[];
}

const SensorChart = ({ measurements }: SensorChartProps) => {
    const [data, setData] = useState<DataPoint[]>([]);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Actualiza los datos cuando measurements cambian
        setData(measurements.reverse());
    }, [measurements]);

    const formatXAxis = (tickItem: string) => {
        const date = new Date(tickItem);
        return `${date.getHours()}:${date.getMinutes()}`;
    };

    return (
        <div ref={containerRef} className="w-full h-auto">
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    data={measurements}
                    margin={{ top: 40, right: 30, left: 20, bottom: 50 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="measured_at"
                        tickFormatter={formatXAxis}
                        interval="preserveEnd"
                        minTickGap={15}
                    />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                        type="monotone"
                        dataKey="value"
                        stroke="#8884d8"
                        activeDot={{ r: 8 }}
                    />
                </LineChart>
            </ResponsiveContainer>


            <small className={"text-white"}>Datos obtenidos el&nbsp;
                {data.length > 0
                    ? new Date().toLocaleString()
                    : "N/A"
                }
            </small>


        </div>
    );
};

export default SensorChart;
