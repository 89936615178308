import {Instance, types} from "mobx-state-tree";
import {LanguageStore} from "./stores/language";
import {Language} from "../types/language";
import {createContext, useContext} from "react";
import {PoIStore} from "./stores/poi";
import {GlobalStore} from "./stores/global";
import {ViewStore} from "./stores/view";
import {MinimapStore} from "./stores/minimap";


export const RootStore = types.model({

    languageStore: LanguageStore,
    poiStore: PoIStore,
    globalStore: GlobalStore,

    // Views
    viewStore: ViewStore,

    // Minimap
    minimapStore: MinimapStore
});

let _store: any = null;

export function initializeStore() {

    _store = RootStore.create({

        languageStore: {
            language: {
                name: navigator.language,
                isoCode: navigator.language,
                default: true
            } as Language
        },
        poiStore: {
            tree: undefined
        },
        globalStore: {
            webgl2Supported: false,
            deviceScreenOrientation: window.screen.orientation.type
        },
        viewStore: {

            view: {
                showPoIInfo: false
            }

        },
        minimapStore: {
            minimap: {
                markers: []
            }
        }
    });

    return _store;
}

export type RootInstance = Instance<typeof RootStore>
const RootStoreContext = createContext<null | RootInstance>(null);

export const Provider = RootStoreContext.Provider;

export function useStore(): RootInstance {
    const store = useContext(RootStoreContext);
    if (store === null) {
        throw new Error("Store cannot be null, please add a context provider");
    }
    return store;
}
