import {ModelConstants} from "./ModelConstants";
import {types} from "mobx-state-tree";

const NavigationPanelTabModel = types.model(ModelConstants.NavigationPanelTabModel, {

    id: types.identifierNumber,
    name: types.string,
    active: types.optional(types.boolean, false),

});

export default NavigationPanelTabModel;
