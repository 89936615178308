import {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react";
import useLogger, {LogLevel} from "../../../../hooks/Logger";

interface PoIMediaProps {

    name: string,
    url: string
}

export const PoIMedia = observer(({name, url}: PoIMediaProps) => {

    const videoRef = useRef<HTMLVideoElement | null>()
    const audioRef = useRef<HTMLAudioElement | null>()
    const [mediaType, setMediaType] = useState("")
    const {logger} = useLogger()


    useEffect(() => {

        const extension = url.split('.').pop()

        if (!extension)
            return

        logger(`Checking media type for ${extension}`, LogLevel.INFO)


        const checkMediaType = () => {

            switch (extension) {
                case 'mp4':
                case 'avi':
                case 'mov':
                    setMediaType("video");
                    break;
                case 'jpg':
                case 'jpeg':
                case 'png':
                    setMediaType("image");
                    break;
                case 'mp3':
                case 'wav':
                    setMediaType("audio");
                    break;
                default:
                    setMediaType("unknown");
                    break;
            }

        }

        checkMediaType()

    }, [url]);

    return (
        <>
            {mediaType}
            {mediaType === "video" && (
                <>
                    {videoRef.current && videoRef.current.pause()}
                    <video controls={true} autoPlay={true} className={""} title={name} src={url}/>
                </>
            )}
            {mediaType === "image" && (
                <>
                    <img className={""} title={name} src={url} alt={name}/>
                </>
            )}
            {mediaType === "audio" && (
                <>
                    {audioRef.current && audioRef.current.pause()}
                    <audio autoPlay={true} className={""} title={name} src={url}/>
                </>
            )}

        </>
    );
});
