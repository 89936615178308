import {API_BASE_URL} from "../../config/services";
import axios from "axios";

interface SensorParams {

    page_limit: number,
    page_number: number,
    sort_by: string,
    sort_order: string,
    entity_ids: number[],
    is_alive: boolean,
    raw: boolean
}

export const getSensorsByCriteria = async ({page_limit, page_number, sort_by, sort_order, is_alive, entity_ids, raw}: SensorParams) => {

    try {
        const response = await axios.get(`${API_BASE_URL}/sensor`,
            {
                params: {
                    page_limit,
                    page_number,
                    sort_by,
                    sort_order,
                    is_alive,
                    entity_ids,
                    raw
                }
            });

        return response.data;
    } catch (error) {
        console.error(`Error obteniendo los sensores con id ${entity_ids}`, error);
        throw error;
    }
}

export const getSensorById = async (id: number) => {

    try {
        const response = await axios.get(`${API_BASE_URL}/sensor/${id}`);

        return response.data;
    } catch (error) {
        console.error(`Error obteniendo el sensor con id ${id}`, error);
        throw error;
    }
}
