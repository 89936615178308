import axios from "axios";


export const API_KEY = "ae169b7c6407780b1a0648101cfcd84c";
export const BASE_URL = "https://api.openweathermap.org/data/2.5/weather";

export const getWeatherData = async (lat: number, lon: number) => {

    let url = BASE_URL + `?lat=${lat}&lon=${lon}&appid=${API_KEY}`;

    try {

        let response = await axios.get(url);

        return response.data

    } catch (error) {
        console.error(error);
    }

}
