import {useState} from 'react';

export enum LogLevel {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  DEBUG = 'DEBUG'
}

const useLogger = () => {

  const [logs, setLogs] = useState<string[]>([]);

  const logger = (message: string, level: LogLevel = LogLevel.INFO, caller?: string) => {
    const timestamp = new Date().toLocaleTimeString();
    const formattedMessage = `${caller?caller+" |":""} ${timestamp} - ${level}: ${message}`;

    switch (level) {
      case LogLevel.INFO:
        console.info(`%c${formattedMessage}`, 'color: cyan');
        break;
      case LogLevel.WARNING:
        console.warn(`%c${formattedMessage}`, 'color: orange');
        break;
      case LogLevel.ERROR:
        console.error(`%c${formattedMessage}`, 'color: red');
        break;
      case LogLevel.DEBUG:
        console.debug(`%c${formattedMessage}`, 'color: yellow');
        break;
    }

    setLogs((prevLogs) => [...prevLogs, formattedMessage]);
  };

  return { logger, logs };
};

export default useLogger;
